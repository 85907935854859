<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "Base.vue"
}
</script>

<style lang="scss" scoped>

</style>
